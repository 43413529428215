import dynamic from "next/dynamic"

export default function Icon({ icon, ...props }) {
  let Icon = () => null

  switch (icon) {
    case "bath":
      Icon = dynamic(() => import("public/assets/icons/bath.svg"))
      break
    case "clipboard":
      Icon = dynamic(() => import("public/assets/icons/clipboard.svg"))
      break
    case "cuddles-not-kennels":
      Icon = dynamic(() => import("public/assets/icons/cuddles-not-kennels.svg"))
      break
    case "discounts":
      Icon = dynamic(() => import("public/assets/icons/discounts.svg"))
      break
    case "dog-bowl":
      Icon = dynamic(() => import("public/assets/icons/dog-bowl.svg"))
      break
    case "dog-heart":
      Icon = dynamic(() => import("public/assets/icons/dog-heart.svg"))
      break
    case "dog-shake":
      Icon = dynamic(() => import("public/assets/icons/dog-shake.svg"))
      break
    case "dogtown-difference":
      Icon = dynamic(() => import("public/assets/icons/dogtown-difference.svg"))
      break
    case "free-roaming":
      Icon = dynamic(() => import("public/assets/icons/free-roaming.svg"))
      break
    case "full-groom":
      Icon = dynamic(() => import("public/assets/icons/full-groom.svg"))
      break
    case "group-classes":
      Icon = dynamic(() => import("public/assets/icons/group-classes.svg"))
      break
    case "how-to-enroll":
      Icon = dynamic(() => import("public/assets/icons/how-to-enroll.svg"))
      break
    case "individual-programs":
      Icon = dynamic(() => import("public/assets/icons/individual-programs.svg"))
      break
    case "live-webcams":
      Icon = dynamic(() => import("public/assets/icons/live-webcams.svg"))
      break
    case "locked-pricing":
      Icon = dynamic(() => import("public/assets/icons/locked-pricing.svg"))
      break
    case "meals-meds":
      Icon = dynamic(() => import("public/assets/icons/meals-meds.svg"))
      break
    case "obstacles-hiding-places":
      Icon = dynamic(() => import("public/assets/icons/obstacles-hiding-places.svg"))
      break
    case "positive-reinforcement":
      Icon = dynamic(() => import("public/assets/icons/positive-reinforcement.svg"))
      break
    case "preferential-scheduling":
      Icon = dynamic(() => import("public/assets/icons/preferential-scheduling.svg"))
      break
    case "socialization-exercise":
      Icon = dynamic(() => import("public/assets/icons/socialization-exercise.svg"))
      break
    case "trim-up":
      Icon = dynamic(() => import("public/assets/icons/trim-up.svg"))
      break
    case "webcam":
      Icon = dynamic(() => import("public/assets/icons/webcam.svg"))
      break
    default:
      console.error("An invalid value was provided to the IconList component's icon property")
  }

  return <Icon {...props} />
}
