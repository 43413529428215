import CallToAction from "components/CallToAction"
import Icon from "components/Icon"
import getStoryblokLink from "utilities/getStoryblokLink"
import richText from "utilities/richText"

function Card({ card }) {
  const href = getStoryblokLink(card.cta_link)

  return (
    <div className="max-w-2xl border-4 border-gray-dark rounded-[30px] relative min-h-[325px] h-full">
      <div className="absolute -top-14 left-1/2 -translate-x-1/2 bg-white">
        <div className="bg-white w-64 flex justify-center">{card.icon && <Icon icon={card.icon} />}</div>
      </div>
      <div className="p-10 min-h-[14rem] pt-12">
        {card.title && <h3 className="text-l1 text-center text-green-pine leading-tight pb-2">{card.title}</h3>}
        <div className="font-light leading-7">{richText(card.text)}</div>

        {card.cta_label !== "" && href !== null ? (
          <CallToAction
            href={href}
            target="_self"
            style="tertiary"
            className="absolute -bottom-6 left-1/2 -translate-x-1/2 min-w-max uppercase"
          >
            {card.cta_label}
          </CallToAction>
        ) : null}
      </div>
    </div>
  )
}

export default function IconCardSection({ blok }) {
  return (
    <section className="my-16 px-8">
      <div className="max-w-screen-xl mx-auto">
        {blok.title !== "" ? (
          <h2 className="text-center mb-6 text-l1 lg:text-xl1 text-gray-dark">{blok.title}</h2>
        ) : null}
        <div className="grid lg:grid-cols-3 pt-16 gap-8 gap-y-24 justify-center items-center">
          {blok.cards.map((card, idx) => (
            <Card key={idx} card={card} />
          ))}
        </div>
      </div>
    </section>
  )
}
